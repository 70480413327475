
.navigation{
    font-size: 12px;
    
}
.navigation p {
    color:#888888;
}

.carousel{
    width: 100%;
    height:530px;
    position: relative;
}
.img-container{
    display:flex;
}
.carousel-image{
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.slide{
    opacity: 1;
}
.slide.active{
    animation-name: fade-in;
    animation-duration:0.3s;
    animation-timing-function: ease-in-out;
    
}

.button-container{
    position: absolute;
    
}
.button{
    background-color: rgba(255, 255, 255, 0);   
    border: none;
    color: #c9c9c9;
    border-radius:20%;
}
#prevSlide{
    position: absolute;
    top:45%;
    right:32px;
    z-index: 100;
}
#nextSlide{
    position: absolute;
    top:45%;
    left:32px;
    z-index: 100;
}
.button:hover{
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.082);
}
.button:active{
    background-color: rgba(255, 255, 255, 0.253);
}
.button:focus{
    outline: none;
}

.project-content h5{
    font-size:18px;
}
.project-content p{
    font-size: 14px;
}

@keyframes fade-in {
    0%{
        opacity: 0.8;
        transform: scale(0.7);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
