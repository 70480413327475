.project-header {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.425), rgba(0, 0, 0, 0.116)),
		url('https://res.cloudinary.com/dhz3fae76/image/upload/v1617980554/HT-Company/dhbhtwyoajrr68sf5ww3.jpg');
	background-position: center;
	background-size: cover;
	height: 70vh;
}
.project-header h1 {
	border-bottom: 2px solid white;
	font-size: 50px;
	color: white;
}
.projects-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.project-container {
	position: relative;
}

.project-card-img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.card-body-content {
	position: absolute;
	padding: 10px;
	border-radius: 5px 5px 0 0;
	width: 90%;
	left: 20px;
	text-align: center;
	color: black;
	bottom: 0;
	background-color: white;
}

.card-content-title {
	font-size: 16px;
}

.card-content-text {
	font-size: 13px;
}
