.hero {
	background: linear-gradient(to left top, #121213f1, #3b393921),
		url('https://res.cloudinary.com/dhz3fae76/image/upload/v1618511560/HT-Company/IMG_20210204_134637_1_g19hd1.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 80vh;
	color: #e6e6e6;
	margin-bottom: 50px;
}
#co-name {
	color: #ff1818ee;
	font-size: 55px;
	font-family: 'vazir', sans-serif;
	font-weight: 500;
	padding-right: 20px;
}
#co-sub-name {
	font-size: 25px;
	font-family: 'vazir', sans-serif;
}
.contant {
	padding: 0 20px;
	margin-right: 30px;
	letter-spacing: 1px;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	background: linear-gradient(to left, rgba(0, 0, 0, 0.466), rgba(0, 0, 0, 0));
}

/* .honor-card {
	height: 130px;
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	color: #ffffff;
	font-size: 20px;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	letter-spacing: 2px;

	align-items: center;
	background-image: linear-gradient(to left, #252525d8, #3b39399f), url('./shane-mclendon-EN1tF2EG-50-unsplash.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	transition: 1s;
}
.honor-card::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: linear-gradient(to left, #f125367e, #f12536cb), url('./shane-mclendon-EN1tF2EG-50-unsplash.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	z-index: -1;
	transition: opacity 0.5s;
	opacity: 0;
} */
.honor-card:hover::before {
	opacity: 1;
}

.honor-card-content {
	border-bottom: 3px solid white;
	border-radius: 5%;
	padding: 10px;
}

.home-header {
	color: #ff1818;
	font-family: "vazir", sans-serif;
	font-size: 30px;
	border-bottom: 1px solid;
	padding-bottom: 20px;
	margin-bottom: 15px;
}
.about-text {
	font-size: 14px;
}
.about-image {
	width: 100%;
	height: 400px;
	object-fit: cover;
	border-radius: 10px;
}
.text-align-justify {
	text-align: justify;
}

#project-container {
	background-image: linear-gradient(to left top, #181b1de8, #101213d7),
		url('https://res.cloudinary.com/dhz3fae76/image/upload/v1618511763/HT-Company/IMG_20210120_173755_frgovh.png');
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

.card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.project-image {
	object-fit: cover;
	height: 100%;
	width: auto;
}

.project-title-container {
	background-color: rgba(0, 0, 0, 0.808);
}

.project-title {
	color: white;
	text-decoration: none;
	font-size: 1.3rem;
	transition: 0.2s;
}

.project-title:hover {
	color: #c71f37;
}

.service-card {
	width: 15rem;
	height: 27rem;
}

.service-image {
	height: 200px;
	width: auto;
	object-fit: cover;
}

h5 {
	transition: 1s;
}

h5:hover {
	color: #ff1818;
}

.scope-field {
	background-color: #232425;
}

.img-choose {
	height: 100px;
	width: 100%;
	object-fit: cover;
}

.post-link {
	text-decoration: none;
	color: #000;
}

.post-link:hover {
	color: #ff0026;
}

.post {
	width: 18rem;
}

.post-image {
	height: 200px;
	object-fit: cover;
	width: 100%;
}

@media (max-width: 991px) {
	.service-card {
		width: 20rem;
	}
}

@media (max-width: 768px) {
	.post {
		width: auto;
	}
}
